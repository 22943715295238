@charset "UTF-8";

html {
    font-size: 16px;
    /* 1rem */
}

/* Указываем box sizing */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-family: 'Slackey', sans-serif;
    line-height: 1.5;
    background: #EFF1F7;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]),
a[class] {
    text-decoration-skip-ink: auto;
    text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
        -webkit-transition-duration: 0.01ms !important;
        -o-transition-duration: 0.01ms !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.left-rigth {
    margin-left: 3vw;
    margin-right: 3vw;
}

.nav {
    padding-top: 68px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.soc,
.links {
    display: flex;
    gap: 19px;

}

.copy {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: #E4E8F1;
    padding: 4px 16px;
    align-self: stretch;
    justify-content: space-between;
}

.copy p {
    color: #445371;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;
}

.copy img:hover {
    cursor: pointer;
}

.jesus1 {
    min-height: 100vh;
    background-image: url(../public/img/jesus1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.logo img:hover {
    cursor: pointer;
}

.sol-text {
    padding-top: 86px;
    max-width: 1166px;
    color: #FFF;
    font-family: "Slackey";
    font-size: 137px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 137px */
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 50px;
}

.jesus {
    margin-top: 74px;
    margin-bottom: 130px;
    text-align: center;
}

.jesus p {
    color: #010211;
    font-family: "Slackey";
    font-size: 199px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 199px */
    text-transform: uppercase;
}

.jesus2,
.jesus3 {
    display: flex;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 145px;
}

.jesus2-text {
    color: #010211;
    max-width: 600px;
    font-family: "Slackey";
    font-size: 124px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 124px */
    text-transform: uppercase;
    text-align: left;
}

.jesus3-text {
    text-align: left;
    max-width: 710px;
    color: #010211;
    font-family: "Slackey";
    font-size: 49px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 49px */
    text-transform: uppercase;
}

.jesus3-text p {
    background: #0019FF;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;
}

.buy {
    color: #010211;
    font-family: "Slackey";
    font-size: 136px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 136px */
    text-transform: uppercase;
    margin-bottom: 90px;
    text-align: center;
}

.buy-text {
    display: flex;
    gap: 41px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 230px;
}

.buy-text span {
    color: #010211;
    text-align: center;
    font-family: "Inter";
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 49.5px */
    letter-spacing: -1.32px;
    text-transform: uppercase;
}

.buy-text__1 {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.buy-text__1 .copy {
    padding: 16px 24px;
    justify-content: space-between;
}

.buy-text__1 .copy p {
    color: #010211;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 90.909% */
    letter-spacing: -0.44px;
}

.buy-text__2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.buy-text__2 span {
    max-width: 883px;
}

.buy-text__2 span b {
    color: #1798CF;
    font-style: normal;
    font-weight: normal;
}

.btn {
    background: #010211;
    display: flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 109.091% */
    letter-spacing: -0.44px;
}

.btn:hover {
    cursor: pointer;
}

.btn a {
    color: #fff;
}

.jesus4 {
    margin-top: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 807px;
    background-image: url(../public/img/jesus4.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.jesus5 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 807px;
    background-image: url(../public/img/jesus5.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.jesus5.jesus a {
    padding: 24px 32px;
    border-radius: 16px;
    color: #fff;
    background: #002BFF;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    box-shadow: 0px 4px 28px 0px #002BFF;
    text-transform: uppercase;


}

.jesus4.jesus p {
    color: #fff;
}

.jesus.road {
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 63px;
}

.jesus.road p {
    font-size: 199px;
}

.footer {
    margin-bottom: 24px;
    margin-top: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.footer p {
    color: #445371;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: center;
    font-family: "Slackey";
    /* 15.6px */
    text-transform: uppercase;
    ;
    max-width: 893px
}

.footer span {
    color: #94A0B6;
    text-align: center;
    font-family: "Slackey";
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    /* 200% */
}

.unselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  not supported by any browser */
}



/* BURGER MENU */

.header__burger,
.mob-menu {
    display: none;
}

@media (max-width: 1024px) {
    .jesus.road p {
        font-size: 80px;
    }

    .jesus p {
        font-size: 80px;
    }

    .jesus2 {
        flex-direction: column;
        align-items: center;
    }

    .jesus3 {
        flex-direction: column-reverse;
        align-items: center;
    }

    .jesus2-text {
        text-align: center;
    }

    .jesus3-text {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .menu {
        display: none;
    }

    .nav {
        padding-top: 30px;
    }

    .mob-menu__items {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    .mob-menu-link a {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 110%;
        color: #fff;
    }

    .mob-menu {
        position: fixed;
        background-color: rgba(255, 80, 0, 0);
        /*clip-path: circle(0% at 100% 0); */
        transition: all ease-in-out 200ms;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 8;
        pointer-events: none;
    }

    .mob-menu.active {
        /*clip-path: circle(100% at 100% 0); */
        background-color: rgba(38, 40, 56, 0.9);
        pointer-events: all;
        display: block;
    }

    .header__burger {
        margin-top: 30px;
        margin-right: 24px;
        display: block !important;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 105;
    }

    .header__burger span {
        background-color: #fff;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        top: 9px;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before,
    .header__burger:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before {
        top: 0;
    }

    .header__burger:after {
        bottom: 0;
    }

    .header__burger.active:before {
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after {
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .header__burger.active span {
        transform: scale(0);
    }

    .header-soc {
        display: flex;
        gap: 20px;
    }

    .header-soc a:hover {
        cursor: pointer;
    }

}

@media (max-width: 425px) {
    .sol-text {
        font-size: 55px;
    }

    .jesus p {
        font-size: 70px;
    }

    .jesus2-text {
        font-size: 55px;
    }

    .jesus3-text {
        font-size: 30px;
    }

    .buy {
        font-size: 50px;
    }

    .jesus.road p {
        font-size: 50px;
    }

    .buy-text__1 .copy p {
        font-size: 15px;
    }
}

@media (max-width: 375px) {
    .jesus p {
        font-size: 50px;
    }

    .jesus.road p {
        font-size: 40px;
    }
}